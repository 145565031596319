<template>
    <!-- eslint-disable vue/no-v-model-argument -->
    <main class="grid" :aria-label="$t('accesibility.media.recordings')">
        <!-- LOADER -->
        <loading v-model:active="loader.isLoading" :can-cancel="false" :is-full-page="true" color="#007680" :width="loader.loaderSize" :height="loader.loaderSize" :opacity="loader.opacity" />
        <!-- <div class="col-12 md:col-10 lg:col-10 xl:col-10 m-auto">
            <div class="card flex m-auto">
                <img class="col-6 m-auto sm:col-4 md:col-4 lg:col-4 xl:col-2" :src="logo" alt="Logo del centro" />
            </div>
        </div> -->
        <!-- DATATABLE -->
        <div class="col-12 md:col-10 lg:col-10 xl:col-10 m-auto">
            <div class="card">
                <!-- SUBJECTS, ROOMS AND DIRECTORIES -->
                <DataTable v-if="recordingsTable == false" selection-mode="single" :value="tableValues" responsive-layout="scroll" :rows="9" @rowSelect="onRowSelect">
                    <template #header>
                        <div class="flex flex-wrap justify-content-start">
                            <div class="flex justify-content-center align-items-center">
                                <Button v-if="tableHeader != $t('app.menu.rooms')" icon="pi pi-arrow-left" class="p-button-rounded p-button-text p-button-plain" @click="navigateBack()"></Button>
                            </div>
                            <div class="flex justify-content-center align-items-center">
                                <h3 class="mr-auto mt-3 lg:m-3 xs:mb-2">{{ tableHeader }}</h3>
                            </div>
                        </div>
                    </template>
                    <Column field="name" :header="$t('endpoints.rooms.table.columns.name')" :sortable="true"></Column>
                </DataTable>

                <!-- RECORDINGS TEACHER -->
                <DataTable v-if="recordingsTable == true && userMode == 'teacher'" v-model:selection="recordings" v-model:filters="filters" :value="tableValues" responsive-layout="scroll" :rows="9">
                    <template #header>
                        <div class="flex flex-wrap justify-content-start">
                            <div class="flex justify-content-center align-items-center">
                                <Button v-if="tableHeader != $t('app.menu.rooms')" icon="pi pi-arrow-left" class="p-button-rounded p-button-text p-button-plain" @click="navigateBack(tableHeader)"></Button>
                            </div>
                            <div class="flex justify-content-center align-items-center">
                                <h3 class="mr-auto mt-3 lg:m-3 xs:mb-2">{{ tableHeader }}</h3>
                            </div>
                            <Button id="save-selection" label="Guardar" icon="pi pi-save" class="p-button-center m-auto lg:mr-2 md:mr-2 sm:mr-2 p-4 lg:ml-auto md:ml-auto sm:ml-auto" @click="saveSelection"></Button>
                        </div>
                    </template>
                    <Column v-if="userMode == 'teacher'" selection-mode="multiple" :styles="{ 'min-width': '3rem' }"></Column>
                    <Column v-if="not_recording == true" field="new_name" :header="$t('app.not_recordings')"></Column>

                    <Column v-if="button_recording == true" style="width: 25%">
                        <template #body="tableValues">
                            <Button ref="text" :aria-label="$t('accesibility.recordings.button1')" icon="pi pi-eye" class="p-button-rounded p-button-success mt-2 lg:ml-0 xl:ml-3" @click="visualizeRecordings($event, tableValues.data.new_name)" />
                            <Button v-if="userMode == 'teacher'" :aria-label="$t('accesibility.recordings.button3')" icon="pi pi-pencil" class="p-button-rounded p-button-warning mt-2 lg:ml-0 xl:ml-3" @click="openUpdateConfirmation(tableValues.data.new_name)" />
                            <Dialog v-model:visible="displayUpdate" class="max-w-20rem" header="Confirmation" :modal="true">
                                <div class="grid">
                                    <div class="col-12 mt-2 mx-auto">
                                        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                                        <span>{{ $t("app.recordings_update") }}</span>
                                    </div>
                                    <InputText v-model="update" type="text" class="col-12 mt-4 mx-auto" placeholder="New name" />
                                </div>
                                <template #footer>
                                    <Button label="No" icon="pi pi-times" class="p-button-text" @click="closeUpdateConfirmation" />
                                    <Button label="Yes" icon="pi pi-check" class="p-button-text" autofocus @click="updateName()" />
                                </template>
                            </Dialog>
                            <Button v-if="userMode == 'teacher'" :aria-label="$t('accesibility.recordings.button4')" icon="pi pi-trash" class="p-button-rounded p-button-danger mt-2 lg:ml-0 xl:ml-3" @click="openDeleteConfirmation(tableValues.data.new_name)" />
                            <Dialog v-model:visible="displayConfirmation" header="Confirmation" :style="{ width: '350px' }" :modal="true">
                                <div class="flex align-items-center justify-content-center">
                                    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
                                    <span>{{ $t("endpoints.rooms.card.confirmation") }}</span>
                                </div>
                                <template #footer>
                                    <Button label="No" icon="pi pi-times" class="p-button-text" @click="closeConfirmation" />
                                    <Button label="Yes" icon="pi pi-check" class="p-button-text" autofocus @click="deleteRecording()" />
                                </template>
                            </Dialog>
                        </template>
                    </Column>
                    <Column v-if="button_recording == true" style="width: 75%" field="new_name" :header="$t('endpoints.rooms.table.columns.name')" :sortable="true">
                        <template #body="tableValues">
                            {{ tableValues.data.new_name }}
                        </template>
                    </Column>
                    <Column v-if="userMode == 'teacher'">
                        <template #body="tableValues">
                            <i v-if="tableValues.data.visible" class="pi pi-check-circle mr-2" style="color: green; font-size: 2rem"></i>
                        </template>
                    </Column>
                </DataTable>

                <!-- RECORDINGS STUDENT -->
                <!-- <DataTable v-if="recordingsTable == true && userMode == 'student'" :value="tableValues" :filters="filters" responsive-layout="scroll" :rows="9">
                    <template #header>
                        <div class="flex flex-wrap justify-content-start">
                            <div class="flex justify-content-center align-items-center">
                                <Button v-if="tableHeader != $t('endpoints.home.cards.2.header')" icon="pi pi-arrow-left" class="p-button-rounded p-button-text p-button-plain" @click="navigateBack(tableHeader)"></Button>
                            </div>
                            <div class="flex justify-content-center align-items-center">
                                <h3 class="mr-auto mt-3 lg:m-3 xs:mb-2">{{ tableHeader }}</h3>
                            </div>
                        </div>
                    </template>
                    <Column v-if="not_recording == true" field="new_name" :header="$t('app.not_recordings')"></Column>

                    <Column v-if="button_recording == true" style="width: 5%">
                        <template #body="tableValues">
                            <Button v-if="tableValues.data.visible" ref="text" :aria-label="$t('accesibility.recordings.button1')" icon="pi pi-eye" class="p-button-rounded p-button-success mt-2 lg:ml-0 xl:ml-3" @click="visualizeRecordings($event, tableValues.data.new_name)" />
                        </template>
                    </Column>
                    <Column v-if="button_recording == true" style="width: 75%" field="new_name" :header="$t('endpoints.rooms.table.columns.name')" :sortable="true">
                        <template #body="tableValues">
                            <div v-if="tableValues.data.visible">
                                {{ tableValues.data.new_name }}
                            </div>
                        </template>
                    </Column>
                </DataTable> -->
            </div>
        </div>
    </main>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import axios from "axios";

import { env_config } from "../../config.js";
import { getUserInfo } from "../utils/auth.js";
import { getId, getType } from "../utils/utils.js";
import { RepositoryFactory } from "../repositories/RepositoryFactory";
import { mapState } from "vuex";

const CentersRepository = RepositoryFactory.get("centers");
const UsersRepository = RepositoryFactory.get("users");
const CategoriesRepository = RepositoryFactory.get("categories");
const RecordingsRepository = RepositoryFactory.get("recordings");

export default {
    components: {
        Loading
    },
    data() {
        let { logo } = getUserInfo();
        return {
            recordingsTable: false,
            tableHeader: "",
            tableValues: [],
            userMode: null,
            userId: null,
            roomId: "",
            update: "",
            directoryName: "",
            directory: "",
            categoryId: "",
            roomDialog: false,
            displayConfirmation: false,
            displayUpdate: false,
            logo: logo,
            selectedRoom: null,
            button_recording: true,
            not_recording: false,
            recordings: [],
            filters: {
                is_deleted: {
                    value: false,
                    matchMode: "equals"
                }
            }
        };
    },
    computed: {
        ...mapState(["loader"])
    },
    created() {
        document.title = this.$t("accesibility.title.recordings");
        let { user_mode, user_id } = getUserInfo();
        this.userMode = user_mode;
        this.userId = user_id;
        if (this.userMode == "teacher") {
            this.getUserRooms(this.userId);
        } else {
            // this.getCenterCategories();
            this.$router.push("/access");
        }
    },
    methods: {
        async getUserRooms(user_id) {
            this.tableHeader = this.$t("app.menu.rooms");
            let response = await UsersRepository.getRooms(user_id);
            this.tableValues = response.data.results;
        },
        // async getUserCategories() {
        //     this.tableHeader = this.$t("endpoints.home.cards.2.header");
        //     let { user_id } = getUserInfo();
        //     let response = await UsersRepository.getCategories(user_id);
        //     this.tableValues = response.data.results;
        // },
        async getCategoriesRooms(category_id) {
            this.tableHeader = this.$t("app.menu.rooms");
            this.categoryId = category_id;
            this.tableValues = null;
            let response = await CategoriesRepository.getRooms(category_id);
            this.tableValues = response.data.results;
        },
        async getRoomDirectories(roomId) {
            this.tableHeader = this.$t("app.menu.recordings");
            this.roomId = roomId;
            this.tableValues = null;
            try {
                let response = await axios.get(`${env_config.API_BASE_DOMAIN}/vods/${roomId}`);
                // let response = await axios.get(`http://localhost:8001/vods/${roomId}`);
                this.tableValues = response.data;
                // console.log(this.tableValues);
                this.button_recording = true;
                this.not_recording = false;
            } catch (error) {
                this.button_recording = false;
                this.not_recording = true;
            }
            // if (response.data.length == 0 || response.status == 404) {
            // } else {
            // }
        },
        async getDirectoryRecordings(directoryName) {
            try {
                let response = await RecordingsRepository.getRecordings(this.roomId, directoryName);
                this.tableValues = response.data;
            } catch (error) {
                console.log(error);
            }
        },
        async getCenterCategories() {
            this.tableHeader = this.$t("endpoints.home.cards.2.header");
            let { center_id } = getUserInfo();
            let response = await CentersRepository.getCategories(center_id);
            this.tableValues = response.data.results;
        },
        async downloadRecordings(directoryName) {
            var url = `${env_config.API_BASE_DOMAIN}/api/v1/recordings/${this.roomId}/${directoryName}/download/`;
            window.open(url, "_blank");
        },
        async updateName() {
            let newVodName = this.update;
            // await RecordingsRepository.editNameRecordings(this.roomId, this.directory, newName);
            await axios.put(`${env_config.API_BASE_DOMAIN}/vods/${this.roomId}/${this.directory}`, { new_vod_name: newVodName });
            this.getRoomDirectories(this.roomId);
            this.displayUpdate = false;
        },
        async deleteRecording() {
            await axios.delete(`${env_config.API_BASE_DOMAIN}/vods/${this.roomId}/${this.directory}`);
            // let response = await RecordingsRepository.deleteRecordings(this.roomId, this.directory);
            this.getRoomDirectories(this.roomId);
            this.displayConfirmation = false;
        },
        async saveSelection() {
            // let response = await axios.post(`http://localhost:8001/vods/${this.roomId}`, this.recordings);
            // this.recordings = response;
            for (let i = 0; i < this.recordings.length; i++) {
                this.recordings[i].visible = !this.recordings[i].visible;
            }

            await axios.post(`${env_config.API_BASE_DOMAIN}/vods/${this.roomId}`, this.recordings);
            await this.getRoomDirectories(this.roomId);

            this.recordings = [];
        },
        onRowSelect(row) {
            if (row.data.self && getType(row.data.self) == "categories") {
                this.getCategoriesRooms(getId(row.data.self));
            } else {
                // console.log(row.data);
                this.recordingsTable = true;
                this.tableHeader = this.$t("app.menu.recordings");
                this.selectedRoom = getId(row.data.self);
                this.getRoomDirectories(getId(row.data.self));
            }
        },
        navigateBack() {
            // if (header == this.$t("app.menu.recordings") && this.userMode == "teacher") {
            this.getUserRooms(this.userId);
            // } else if (header == this.$t("app.menu.sessions") && this.userMode == "student") {
            //     this.getCenterCategories();
            // } else {
            this.recordingsTable = false;
            //     this.getCategoriesRooms(this.categoryId);
            // }
        },
        visualizeRecordings(event, directoryName) {
            this.$router.push(`/vod/${this.selectedRoom}/${directoryName}`);
            event.preventDefault();
        },
        openDeleteConfirmation(y) {
            this.displayConfirmation = true;
            this.directory = y;
        },
        closeConfirmation() {
            this.displayConfirmation = false;
        },
        openUpdateConfirmation(x) {
            this.displayUpdate = true;
            this.directory = x;
        },
        closeUpdateConfirmation() {
            this.displayUpdate = false;
        },
        showSuccess(detail) {
            this.$toast.add({ severity: "success", summary: "Success", detail: detail, life: 3000 });
        }
    }
};
</script>
